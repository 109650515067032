import React, { useEffect } from "react";
import style from "./Season2.module.css";
import { Anchor, Carousel, Collapse, Steps, Modal } from "antd";
import RegistrationForm from "../../Components/RegistrationForm/RegistrationForm";
import Trophy from "../../asset/Image/trophy.png";
import { Helmet } from "react-helmet";
import { getCannonicalUrl } from "../../helper/helper";
import Logo from "../../asset/Image/Season_2.jpeg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import slider1Img from "../../asset/Banner/dance-dynamite-slider-1-min.jpg";
import slider2Img from "../../asset/Banner/dance-dynamite-slider-2-min.jpg";
const DelayedNotification = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      Modal.info({
        // title: 'Welcome!',
        content: (
          <div>
            <p style={{ fontSize:'20px', fontStyle: 'bold', color:"red", marginBottom: "20px" }}>Online Registration is Now Closed !!! </p>
            <p className={style.about}>Auditions for Dance Dynamite are now closed. We appreciate all your support and enthusiasm. Stay tuned as we’ll be announcing the winners soon. Best of luck to all the talented dancers!</p>
            <p style={{ fontSize:'16px', fontStyle: 'bold', color:"blue", marginTop: "20px" }}>Best Regards,</p>
            <p style={{ fontSize:'16px', fontStyle: 'bold', color:"blue" }}>Team Dance Dynamite</p>
          </div>
        ),
        onOk() {},
      });
    }, 2500); // 2.5 seconds delay

    return () => clearTimeout(timer);
  }, []);

  return null;
};
const Season2 = () => {
  const currentUrl = window.location.href;
  const pathname = new URL(currentUrl).pathname;
  const isProdEnv = process.env.REACT_APP_NODE_ENV_DD === "production";
  //console.log(process.env.REACT_APP_NODE_ENV_DD, "code");
  //console.log(isProdEnv, "status");
  const seo_title =
    "Interschool Solo & Group Dance Competition | Dance Dynamite Season 2";
  const seo_description =
    "Orchids International School's Dance Dynamite Season 2 interschool dance competition is filled with fun. Visit now to register for solo and group dance competitions online";
  
    const sliderImages = [
      {
        id: 1,
        image: slider1Img,
      },
      {
        id: 2,
        image: slider2Img,
      },
    ];


  const stepsItems = [
    {
      title:
        "The auditions will be held in your nearby Orchids the International School.",
      description:
        "Age category: Sub Junior- Grades/Standard 1 to 3, Junior- Grades/Standard 4-7, Senior- Grades/Standard 8-10",
    },
    // {
    //   title: "Theme: Will be announced soon",
    //   description: "",
    // },
    {
      title: "Song selection: Clean and age-appropriate lyrics.",
      description: "",
    },
    {
      title:
        "Performance Duration: Music should not exceed 1 minute 30 seconds. ",
      description: "",
    },
    {
      title:
        "Participants need to carry their track in a pen drive in mp3 format.",
      description: "",
    },
    {
      title:
        "The students and the teacher/accompanist/in-charge should carry valid age proof (school ID or government ID of the participant) with a participant passport-size photo of themselves to the audition venue.",
      description: "",
    },
    {
      title: "A participant can make use of props in the performance. ",
      description: "",
    },
    {
      title:
        "Winners of the audition round will move to the Final round conducted in January 2025.",
      description: "",
    },
    {
      title:
        "Participants need to register themselves by filling out the registration form.",
      description: "",
    },
    {
      title:
        "Judges’ decision will be final. No questions will be entertained.",
      description: "",
    },
  ];

  const GroupGuidlines = [
    {
      title: "Group Size: 4-12 members",
      description:
        "Age category:  Junior: Grades/Standard 4 to 7, Senior: Grades/Standard 8 to 10",
    },
    // { title: "Theme:", description: "Will be announced soon" },
    {
      title: "Song selection",
      description: "Clean and age-appropriate lyrics.",
    },
    {
      title: "Performance Duration: ",
      description:
        "Music should not exceed 3.30 minutes. The total duration should be max. 5 minutes including entry and exit of the participant on stage.",
    },
    {
      title: "There will be a negative marking for exceeding the time limit.",
      description: "",
    },
    {
      title:
        "The students and the teacher/accompanist/in-charge should carry valid age proof (school ID or government ID of the participant) with a participant passport-size photo of themselves to the audition venue.",
      description: "",
    },
    {
      title: "Groups need to carry their track in a pen drive in mp3 format.",
      description: "",
    },
    {
      title:
        "Each group should have a name representing their school, academy, or dance form.",
      description: "",
    },
    {
      title: "A group can make use of props in the performance.",
      description: "",
    },
    {
      title:
        "One winner from each city will be selected in every category for the final round.",
      description: "",
    },
    {
      title:
        "Winners of the audition round will move to the Final round conducted in January 2025.",
      description: "",
    },
    {
      title:
        "Judges’ decision will be final. No questions will be entertained.",
      description: "",
    },
  ];

  const NationalSoloGuide = [
    {
      title:
        "Age category: Sub Junior- Grades/Standard 1 to 3, Junior- Grades/Standard 4-7, Senior- Grades/Standard 8-10",
      description: "",
    },
    { title: "Theme:", description: "Will be announced soon" },
    {
      title: "Song selection:",
      description: "Clean and age-appropriate lyrics.",
    },
    {
      title: "Performance Duration: ",
      description: "Music should not exceed 5 minutes. ",
    },
    {
      title: "There will be a negative marking for exceeding the time limit.",
      description: "",
    },
    {
      title:
        "Participants need to carry their track in a pen drive in mp3 format.",
      description: "",
    },
    {
      title:
        "The students and the teacher/accompanist/in-charge should carry valid age proof (school ID or government ID of the participant) with a participant passport-size photo of themselves to the audition venue.",
      description: "",
    },
    {
      title: "A participant can make use of props in the performance.",
      description: "",
    },
    {
      title:
        "Judges’ decision will be final. No questions will be entertained.",
      description: "",
    },
  ];

  const NationalGroupGuidelines = [
    {
      title: "Group Size: 4-12 members",
      description:
        "Age category:  Junior: Grades/Standard 4 to 7, Senior: Grades/Standard 8 to 10",
    },
    { title: "Theme: ", description: "Will be announced soon" },
    {
      title: "Song selection: ",
      description: "Clean and age-appropriate lyrics.",
    },
    {
      title: "Performance Duration:",
      description:
        "Music should not exceed 5.00 minutes. The total duration should be max. 6.00 minutes including entry and exit of the group on stage.",
    },
    {
      title: "There will be a negative marking for exceeding the time limit.",
      description: "",
    },
    {
      title:
        "The students and the teacher/accompanist/in-charge should carry valid age proof (school ID or government ID of the participant) with a participant passport-size photo of themselves to the audition venue.",
      description: "",
    },
    {
      title: "Groups need to carry their track in a pen drive in mp3 format.",
      description: "",
    },
    {
      title:
        "Each group should have a name representing their school, academy, or dance form.",
      description: "",
    },
    {
      title: "A group can make use of props in the performance.",
      description: "",
    },
    {
      title:
        "One winner from each city will be selected in every category for the final round.",
      description: "",
    },
    {
      title:
        "Winners of the audition round will move to the Final round conducted in January 2025.",
      description: "",
    },
    {
      title:
        "Judges’ decision will be final. No questions will be entertained.",
      description: "",
    },
  ];

  const soloRules = [
    {
      title:
        "Time limits must be taken care of, if not, it can lead to negative marking.",
      description: "",
    },
    {
      title:
        "The decision of the judges and organising committee will be final.",
      description: "",
    },
    {
      title: "Violation of any above rules can lead to disqualification.",
      description: "",
    },
    // {title:"The competition will be judged based on the following criteria:",
    // description:""
    // },
  ];
  const GroupRules = [
    {
      title:
        "Time limits must be taken care of, if not, it can lead to negative marking.",
      description: "",
    },
    {
      title:
        "The decision of the judges and organising committee will be final.",
      description: "",
    },
    {
      title: "Violation of any above rules can lead to disqualification.",
      description: "",
    },
    {
      title:
        "Selected solo/ group participants will perform in the final round.",
      description: "",
    },
    {
      title: "The final round will conducted in January 2025.",
      description: "",
    },
    {
      title: "The tentative venue of the final round will be Pune.",
      description: "",
    },
    {
      title:
        "Participants will have to travel to Pune, the onus of travel and accommodation has to be borne by the participants.",
      description: "",
    },
  ];

  const NationalSoloRules = [
    {
      title:
        "Time limits must be taken care of, if not, it can lead to negative marking.",
      description: "",
    },
    {
      title:
        "The decision of the judges and organising committee will be final.",
      description: "",
    },
    {
      title: "Violation of any above rules can lead to disqualification.",
      description: "",
    },
    {
      title:
        "Selected solo/ group participants will perform in the final round.",
      description: "",
    },
    {
      title: "The final round will conducted in January 2025.",
      description: "",
    },
    {
      title: "The tentative venue of the final round will be Pune.",
      description: "",
    },
    {
      title:
        "Participants will have to travel to Pune, the onus of travel and accommodation has to be borne by the participants.",
      description: "",
    },
  ];

  const NationalGroupRules = [
    {
      title:
        "Time limits must be taken care of, if not, it can lead to negative marking.",
      description: "",
    },
    {
      title:
        "The decision of the judges and organising committee will be final.",
      description: "",
    },
    {
      title: "Violation of any above rules can lead to disqualification.",
      description: "",
    },
    {
      title:
        "Selected solo/ group participants will perform in the final round.",
      description: "",
    },
    {
      title: "The final round will conducted in November/December 2024.",
      description: "",
    },
    {
      title: "The tentative venue of the final round will be Pune.",
      description: "",
    },
    {
      title:
        "Participants will have to travel to Pune, the onus of travel and accommodation has to be borne by the participants.",
      description: "",
    },
  ];

  const judgingSolo = [
    {
      title: "Energy: ",
      description:
        "Performers should showcase the right amount of energy according to the choice of music",
    },
    {
      title: "Musicality:",
      description:
        "Performer should perform the choreography on the correct rhythm with the movement, highly inclined towards music",
    },
    {
      title: "Clean Movements:",
      description:
        " Performers should present their movement in proper body alignment",
    },
    {
      title: "Creativity or any Wow factor: ",
      description:
        "Performer showcasing creative factors like originality and wow elements",
    },
    {
      title: "Expression: ",
      description:
        "Performer should showcase their performance with the right body and facial expression",
    },
  ];

  const judgingGroup = [
    {
      title: "Choreography:",
      description:
        "Flow of movements in sequence which is presentably appealing",
    },
    {
      title: "Musicality:",
      description:
        "Correct rhythm with the movement, highly inclined towards music",
    },
    {
      title: "Synchronization:",
      description: "Coordination of movement and transitions in the group",
    },
    {
      title: "Expression: ",
      description:
        "Showcasing the right body and facial expression according to the music",
    },
    {
      title: "Formations:",
      description:
        "Arrangement of the dancers presenting the choreography in creative patterns",
    },
  ];

  const NatinalSoloJudging = [
    {
      title: "Energy:",
      description:
        "Performers should showcase the right amount of energy according to the choice of music",
    },
    {
      title: "Musicality: ",
      description:
        "Performer should perform the choreography on the correct rhythm with the movement, highly inclined towards music",
    },
    {
      title: "Clean Movements: ",
      description:
        "Performers should present their movement in proper body alignment",
    },
    {
      title: "Creativity or any Wow factor:",
      description:
        "Performer showcasing creative factors like originality and wow elements",
    },
    {
      title: "Expression: ",
      description:
        "Performer should showcase their performance with the right body and facial expression",
    },
  ];

  const NationalJudgingGroup = [
    {
      title: "Choreography: ",
      description:
        "Flow of movements in sequence which is presentably appealing",
    },
    {
      title: "Musicality: ",
      description:
        "Correct rhythm with the movement, highly inclined towards music",
    },
    {
      title: "Synchronization:",
      description: "Coordination of movement and transitions in the group",
    },
    {
      title: "Expression: ",
      description:
        "Showcasing the right body and facial expression according to the music",
    },
    {
      title: "Formations:",
      description:
        "Arrangement of the dancers presenting the choreography in creative patterns",
    },
  ];

  const SoloAwards = [
    {
      title: "Sub - Junior",
      winner_price: " ₹25,000",
      runner_price: " ₹15,000",
    },
    {
      title: "Junior",
      winner_price: " ₹25,000",
      runner_price: " ₹15,000",
    },
    {
      title: "Senior",
      winner_price: " ₹25,000",
      runner_price: " ₹15,000",
    },
  ];

  const GroupAwards = [
    {
      title: "Junior",
      winner_price: " ₹50,000",
      runner_price: " ₹25,000",
    },
    {
      title: "Senior",
      winner_price: " ₹50,000",
      runner_price: " ₹25,000",
    },
  ];

  const stepsComponent = (
    <Steps
      progressDot
      current={9}
      direction="vertical"
      items={judgingSolo}
      // fontWeight={500}
    />
  );

  const items = [
    {
      key: "1",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Guidelines
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={stepsItems}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          General Rules
        </div>
      ),
      children: (
        <Steps progressDot current={9} direction="vertical" items={soloRules} />
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Judging Criteria for Solo Dance Competition
        </div>
      ),
      children: stepsComponent,
    },
  ];

  const Groupitems = [
    {
      key: "1",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Guidelines
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={12}
          direction="vertical"
          items={GroupGuidlines}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          General Rules
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={GroupRules}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Judging Criteria for Group Dance Competition
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={judgingGroup}
        />
      ),
    },
  ];

  const NationalSoloitems = [
    {
      key: "1",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Guidelines
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={NationalSoloGuide}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          General Rules
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={NationalSoloRules}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Judging Criteria for Solo Dance Competition
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={NatinalSoloJudging}
        />
      ),
    },
  ];

  const NationalGroupitems = [
    {
      key: "1",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Guidelines
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={NationalGroupGuidelines}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          General Rules
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={NationalGroupRules}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ fontSize: "18px", fontWeight: "600", color: "#1677ff" }}>
          Judging Criteria for Group Dance Competition
        </div>
      ),
      children: (
        <Steps
          progressDot
          current={9}
          direction="vertical"
          items={NationalJudgingGroup}
        />
      ),
    },
  ];

  const Faqs = [
    {
      key: "1",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Can a group of students from different schools form a group together?
        </h3>
      ),
      children: <p>Yes, they have to fit in the age category provided</p>,
    },
    {
      key: "2",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Can students from a dance academy participate in the competition?
        </h3>
      ),
      children: <p>Yes</p>,
    },
    {
      key: "3",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Who will be responsible for transport and accommodation if the
          students is selected for National level?
        </h3>
      ),
      children: <p>Parents/Guardians</p>,
    },
    {
      key: "4",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Are props required for the competition?
        </h3>
      ),
      children: <p>Depends on the theme and choreography you choose</p>,
    },
    {
      key: "5",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          What is a government ID? Is it mandatory to bring for the competition?
        </h3>
      ),
      children: (
        <p>
          A government ID is an Aadhaar card of the participant. Yes, it is
          mandatory to bring the Aadhaar card to the competition.
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Where is the Dance Dynamite audition being held in India?
        </h3>
      ),
      children: (
        <p>
          The dance dynamite audition will be organized at selective branches of
          Orchids the International School
        </p>
      ),
    },
    {
      key: "7",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Who can participate in the Dance Dynamite - Season 2 competition?
        </h3>
      ),
      children: (
        <p>
          Children in the age group of 6-15 from any school can participate in
          the Dance Dynamite - Season 2 competition.
        </p>
      ),
    },
    {
      key: "8",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Can a group participate in the Dance Dynamite - Season 2 competition?
        </h3>
      ),
      children: (
        <p>
          Yes, a group of individuals can participate in a dance dynamite
          interschool dance competition.
        </p>
      ),
    },
    {
      key: "9",
      label: (
        <h3 style={{ fontSize: "16px", fontWeight: "600", color: "#1d2939" }}>
          Can a solo dancer participate in the Dance Dynamite - Season 2
          Competition?
        </h3>
      ),
      children: <p>Yes, a solo dancer can participate in the competition.</p>,
    },
  ];

  let breadcrumbsArr = [
    { label: "Home ", link: "/" },
    { label: " Season 2", link: "/season-2" },
  ];

  let sdata = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    name: "Dance Dynamite Season 2",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://dancedynamiteindia.com",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Season 2",
        item: "https://dancedynamiteindia.com/season-2",
      },
    ],
  });
  let fData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    name: "Frequently Asked Questions",
    mainEntity: Faqs?.map((each) => {
      return {
        "@type": "Question",
        name: each?.label.props.children,
        acceptedAnswer: {
          "@type": "Answer",
          text: each?.children.props.children,
        },
      };
    }).filter(Boolean),
  });

  //  console.log(fData);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCannonicalUrl(pathname)} />
        <meta
          name="googlebot"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <meta
          name="robots"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <title>{seo_title} </title>
        <meta name="description" content={seo_description} />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: sdata }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: fData }}
        />
      </Helmet>
      <DelayedNotification />
      <div className={style.seasonContent}>
        <div className={style.banner}>
          {/* <img
            // src="https://cdn-cms.orchidsinternationalschool.com/media/dance_dynamite.png"
            src={Banner}
            alt="Dance-dynamite-2"
          /> */}
            <Carousel arrows autoplay>
          {sliderImages.map((item) => (
            <div
              key={item.id}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={item.image}
                alt={`Dance Dynamite S02 - ${item.id}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </Carousel>
        </div>
        <div className={style.breadcrumbs}>
          <Breadcrumbs breadcrumbs={breadcrumbsArr} />
        </div>
        <div className={style.content}>
          <div className={style.eventInfo}>
            <div className={style.contentNav}>
              <Anchor
                direction="horizontal"
                className={style.customAnchor}
                items={[
                  {
                    key: "details",
                    href: "#details",
                    title: (
                      <span className={style.customAnchorTitle}>Details</span>
                    ),
                  },
                  {
                    key: "guidlines",
                    href: "#guidlines",
                    title: (
                      <span className={style.customAnchorTitle}>
                        Guidelines & Rules
                      </span>
                    ),
                  },
                  {
                    key: "awards",
                    href: "#awards",
                    title: (
                      <span className={style.customAnchorTitle}>Awards</span>
                    ),
                  },
                  {
                    key: "faqs",
                    href: "#faqs",
                    title: (
                      <span className={style.customAnchorTitle}>FAQs</span>
                    ),
                  },
                ]}
              />
            </div>
            <div className={style.details} id="details">
              <div className={style.heading}>
                <h1>Dance Dynamite Season 2: Interschool Dance Competition</h1>
              </div>
              <div className={style.about}>
                <p>
                  Dance Dynamite emerges once more, announcing the return of a
                  grand celebration of rhythm and movement. This prestigious
                  National Level Competition, organized in partnership with
                  Orchids the International School, covers the vast expanse of
                  India in pursuit of a singular talent—a dancer of such
                  exceptional creativity, uniqueness, and zeal, that they stand
                  out as the one in a million.
                </p>
                <p>
                  As the curtains rise on Season 2 of Dance Dynamite, the stage
                  is set not just for a competition, but for a festival of dance
                  that unites diverse forms and expressions. It is a quest to
                  bestow the prestigious Dance Dynamite Trophy upon the one
                  dancer who, with their unbeatable spirit and innovative
                  artistry, will captivate the nation.
                </p>
                <p>
                The second phase of audition will occur on 25th August 2024 at all nearby Orchids The International Schools. Contestants who appeared in the first phase are not eligible for this round. The grand finale will take place in Pune in January 2025.
                </p>                
              </div>
            </div>
            <div className={style.guidlines} id="guidlines">
              <div className={style.guidlinesHead}>
                <h2>Audition Round - Solo Dance Competition</h2>
                <div className={style.rules}>
                  <Collapse
                    accordion
                    items={items}
                    defaultActiveKey={["1"]}
                    ghost={true}
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      zIndex: "-11677ff",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.guidlines} id="part-2">
              <div className={style.guidlinesHead}>
                <h2>Audition Round - Group Dance Competition</h2>
                <div className={style.rules}>
                  <Collapse
                    accordion
                    items={Groupitems}
                    defaultActiveKey={["1"]}
                    ghost={true}
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      zIndex: "-11677ff",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.guidlines} id="part-2">
              <div className={style.guidlinesHead}>
                <h2>National Round - Solo Dance Competition</h2>
                <div className={style.rules}>
                  <Collapse
                    accordion
                    items={NationalSoloitems}
                    defaultActiveKey={["1"]}
                    ghost={true}
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      zIndex: "-11677ff",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.guidlines} id="part-2">
              <div className={style.guidlinesHead}>
                <h2>National Round - Group Dance Competition</h2>
                <div className={style.rules}>
                  <Collapse
                    accordion
                    items={NationalGroupitems}
                    defaultActiveKey={["1"]}
                    ghost={true}
                    style={{
                      textAlign: "left",
                      fontWeight: "500",
                      zIndex: "-11677ff",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={style.cardContainer} id="awards">
              <h2>Prizes for Solo Dance Competition</h2>

              <div className={style.soloAwards}>
                {SoloAwards?.map((item) => (
                  <div className={style.card}>
                    <div className={style.cardLeft}>
                      <div className={style.header}>
                        <span className={style.headerName}>{item?.title}</span>
                      </div>
                      <div className={style.Awardcontent}>
                        <div className={style.priceCategory}>
                          <p>Winner : </p>
                          <p>{item?.winner_price}</p>
                        </div>
                        <div className={style.priceAmount}>
                          <p>Runner-Up : </p>
                          <p>{item?.runner_price}</p>
                        </div>
                      </div>
                    </div>
                    <div className={style.cardRight}>
                      {/* <span className={style.certificate}>Certificate</span> */}
                      <img
                        src={Trophy}
                        alt="Trophy"
                        style={{
                          width: "100%",
                          height: "auto",
                          bottom: "20px",
                          // position: "absolute",
                          right: "40px",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={style.cardContainer}>
              <h2>Prizes for Group Dance Competition</h2>
              <div className={style.GroupAwards}>
                {GroupAwards?.map((item) => (
                  <div className={style.card}>
                    <div className={style.cardLeft}>
                      <div className={style.header}>
                        <span className={style.headerName}>{item?.title}</span>
                      </div>
                      <div className={style.Awardcontent}>
                        <div className={style.priceCategory}>
                          <p>Winner : </p>
                          <p>{item?.winner_price}</p>
                        </div>
                        <div className={style.priceAmount}>
                          <p>Runner-Up : </p>
                          <p>{item?.runner_price}</p>
                        </div>
                      </div>
                    </div>
                    <div className={style.cardRight}>
                      {/* <span className={style.certificate}>Certificate</span> */}
                      <img
                        src={Trophy}
                        alt="Trophy"
                        style={{
                          width: "100%",
                          height: "auto",
                          bottom: "20px",
                          // position: "absolute",
                          right: "40px",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={style.faqs} id="faqs">
              <div className={style.faqHeading}>
                <h2>FAQs on Dance Dynamite Season 2</h2>
              </div>
              <div className={style.accordion}>
                <Collapse
                  accordion
                  items={Faqs}
                  // ghost={true}
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    zIndex: "-11677ff",
                    backgroundColor: "#ffffff",
                    borderRight: "0px",
                    borderLeft: "0px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.regForm}>
            <RegistrationForm isOpen={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Season2;
