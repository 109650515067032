import React from "react";
import style from "./Season1.module.css";
import RegistrationForm from "../../Components/RegistrationForm/RegistrationForm";
import { Helmet } from "react-helmet";
import { getCannonicalUrl } from "../../helper/helper";
import Logo from "../../asset/Image/Season_1.jpeg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

export const Season1 = () => {
  const currentUrl = window.location.href;
  const pathname = new URL(currentUrl).pathname;
  const isProdEnv = process.env.REACT_APP_NODE_ENV_DD === "production"        
  //console.log(process.env.REACT_APP_NODE_ENV_DD,'code');
  //console.log(isProdEnv,'status');
  const seo_title =
    "Join Dance Dynamite: India's Premier Solo Dance Competition for Ages 8-15!";
  const seo_description =
    "Calling all young dancers! Experience the thrill of Dance Dynamite, a nationwide solo dance competition across 14 cities in India. Audition for a chance to win prizes worth Rs.10,000 and the opportunity to be judged by Bollywood Dancer and Actor Sushant Pujari. Register now and step into the spotlight!";

    let breadcrumbsArr = [{ label: "Home ", link: "/" },{ label: " Season 1", link: "/season-1" }];

  let sdata = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "name" : "Dance Dynamite Season 1",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://dancedynamiteindia.com",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Season 1",
        item: "https://dancedynamiteindia.com/season-1",
      },
    ],
  });

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCannonicalUrl(pathname)} />
        <meta
          name="googlebot"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <meta name="robots" content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}/>
        <title>{seo_title} </title>
        <meta name="description" content={seo_description} />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: sdata }}
      />     
      </Helmet>      
      <div
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <div className={style.banner}>
          <img
            src="https://cdn-cms.orchidsinternationalschool.com/media/events/dance-dynamite-revised.jpg"
            alt="Dance dynamite S01"
          />
        </div>
        <div className={style.breadcrumbs}><Breadcrumbs breadcrumbs={breadcrumbsArr} /></div>
        <div className={style.contents}>
          <div className={style.details}>
            <p>
              Dance Dynamite is a solo dance competition that is sure to be a
              thrilling experience for all young dancers. The competition is
              open to dancers aged 6-15 years and the auditions will take place
              in 16 different cities all over India.
            </p>
            <br />
            <p>
              <strong>
                The final round will be held in Indore on 7th January 2024.
                Finalists will get an opportunity to meet Bollywood Dancer and
                Actor Sushant Pujari (ABCD Movie Fame). The competition features
                total prize money worth Rs. 100000/-.
              </strong>
            </p>
            <br />
            <p>
              Auditions will be held in 16 different cities: Bangalore, Mumbai,
              Pune, Aurangabad, Nagpur, Indore, Bhopal, Jabalpur, Jaipur,
              Jodhpur, Gurgaon, Rohtak, Sonepat, Kolkata, Chennai, Ahmednagar and Hyderabad.{" "}
            </p>
            <br />
            <p>
              Shortlisted qualifiers will perform in the Grand Finale in Indore
              and become India's first Dance Dynamite! The national round will
              be judged by highly qualified judges.{" "}
            </p>
            <br />
            <br />
            <p>
              <strong>
                Come showcase your skills and gain experience in the dazzling
                field of dance. So, don't wait and register NOW!
              </strong>
            </p>
          </div>
          <div className={style.form}>
            <RegistrationForm isOpen={false} />
          </div>
        </div>
      </div>
    </>
  );
};
