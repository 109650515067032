import React from 'react';
import style from "./SuccessMessage.module.css";
import { Result } from 'antd';
export const SuccessMessage = () => {
  return (
    <>
    <div className={style.result}>
       <Result
    status="success"
    title="Registration Successful"
    subTitle="Best of Luck!"
  />
    </div>
    </>
  )
}
