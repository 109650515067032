import React from "react";
import { Routes, Route } from "react-router-dom";
import Season2 from "../Pages/Season2/Season2";
import { Season1 } from "../Pages/Season1/Season1";
import HomePage from "../Pages/HomePage/HomePage";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/season-1" element={<Season1 />} />
      <Route path="/season-2" element={<Season2 />} />
    </Routes>
  );
};

export default AllRoutes;
