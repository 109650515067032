import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ breadcrumbs }) => {
return (
<div className="breadcrumbs">
{breadcrumbs.map((breadcrumbs, index) => (
<span key={index}>
{index > 0 && <span className="separator">/</span>}
{breadcrumbs.link ? (
<Link to={breadcrumbs.link}>{breadcrumbs.label}</Link>
) : (
<span>{breadcrumbs.label}</span>
)}
</span>
))}
</div>
);
};

export default Breadcrumbs;